import styled from "@emotion/styled"

export const FloatingText = styled.p`
  position: absolute;
  left: 50%;
  top: 2rem;
  transform: translateX(-50%);
  font-size: 14vw;
  opacity: 0.025;
  color: #fefefe;
  text-transform: uppercase;
  font-family: "Yantramanav";
  font-weight: 700;
  white-space: nowrap;
  margin: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`
