import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import SEO from "../../components/seo"
import Header from "../../components/header/header"
import Video from "../../components/video/video"
import {
  WebPortfolio,
  WebPortfolioLeft,
  WebPortfolioRight,
  WebPortfolioItemContent,
  WebPortfolioItem,
  ProgressBar,
  WebPortfolioLeftTitle,
} from "../../styled-components/web-design-styles"
import { FloatingText } from "../../styled-components/floating-text"

class WebPortfolioPage extends Component {
  deskScrollH() {
    document.querySelector(".web-portfolio").addEventListener(
      "wheel",
      e => {
        e.deltaY > 0
          ? this.deltaRight(Math.abs(e.deltaY))
          : this.deltaLeft(Math.abs(e.deltaY))
      },
      { passive: true }
    )
  }

  deltaRight(delta) {
    const portRight = document.querySelector(".web-portfolio__right")
    portRight.scrollLeft += delta
    this.progressBar((portRight.scrollLeft += delta))
  }

  deltaLeft(delta) {
    const portRight = document.querySelector(".web-portfolio__right")
    portRight.scrollLeft -= delta
    this.progressBar((portRight.scrollLeft -= delta))
  }

  progressBar(scrollDist) {
    const progBar = document.querySelector(".progress-bar")
    const portRightDist =
      document.querySelector(".web-portfolio__right").scrollWidth -
      document.querySelector(".web-portfolio__right").clientWidth
    const progress = Math.round((scrollDist * 100.0) / portRightDist)
    if (progress >= 2 || progress <= 100) {
      progBar.style.width = `${progress}%`
    }
  }

  componentDidMount() {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      this.deskScrollH()
      Object.assign(document.querySelector("body").style, {
        overflow: "hidden",
      })

      Object.assign(document.querySelector(".web-portfolio").style, {
        height: `${window.innerHeight}px`,
      })
    }
  }

  componentWillUnmount() {
    document.querySelector("body").style.overflow = "auto"
  }

  render() {
    const sanity = this.props.data.allSanityPortfolio
    const items = []
    for (let i = 0; i < sanity.edges[0].node.portItem.length; i++) {
      items.push(
        <WebPortfolioItem
          href={sanity.edges[0].node.portItem[i].hovLink}
          target="_blank"
          rel="noopener"
          key={i}
        >
          <div className="overlay"></div>
          <Video
            vidSrc={sanity.edges[0].node.portItem[i].video.asset.url}
            autoPlay={true}
            loop={true}
            poster={sanity.edges[0].node.portItem[i].poster.asset.url}
          />
          <WebPortfolioItemContent>
            <p>{sanity.edges[0].node.portItem[i].hovText}</p>
            <div className="spacer"></div>
            <p>visit website</p>
          </WebPortfolioItemContent>
        </WebPortfolioItem>
      )
    }
    return (
      <>
        <SEO title="Web Design" />
        <Header />
        <FloatingText>We can help</FloatingText>
        <WebPortfolio className="web-portfolio">
          <WebPortfolioLeft>
            <WebPortfolioLeftTitle>
              Web <span>Design</span>
            </WebPortfolioLeftTitle>
            <p>{sanity.edges[0].node.description}</p>
            <Link
              css={css`
                text-decoration: none;
                color: #2497c6;
                text-transform: uppercase;
                letter-spacing: 4px;
                margin-right: 2rem;
                font-weight: 700;
                font-size: 0.875rem;
                position: relative;
                transition: all 0.4s ease-in-out;

                &:hover {
                  color: #fefefe;
                }

                &::before {
                  position: absolute;
                  top: 50%;
                  right: calc(100% + 1rem);
                  display: block;
                  content: "";
                  width: 2rem;
                  height: 1px;
                  background-color: #fff;
                }
              `}
              to="/portfolio"
            >
              Back to portfolio
            </Link>
          </WebPortfolioLeft>
          <WebPortfolioRight className="web-portfolio__right">
            {items}
          </WebPortfolioRight>
          <ProgressBar className="progress-bar" />
        </WebPortfolio>
      </>
    )
  }
}

export const query = graphql`
  {
    allSanityPortfolio(filter: { portPage: { eq: "Web Design" } }) {
      edges {
        node {
          description
          portItem {
            ... on SanityWebItem {
              _key
              hovLink
              hovText
              poster {
                asset {
                  url
                }
              }
              video {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default WebPortfolioPage
